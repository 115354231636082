<template>
  <div>
    <v-form>
      <v-card :color="cardColor">
        <v-container>
          <h3 class=" text-center mb-2">Investigations</h3>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.PCVOnAdmission"
                label="PCV on Admission(%):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.RBSOnAdmission"
                :color="color"
                type="number"
              >
                <template v-slot:label>
                  RBS on Admission(mmoL/L):
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-radio-group v-model.trim="dform.FBCResult" dense row>
            <span class="mr-3">FBC Result: </span>
            <v-radio label="N/A" value="None" color="success"></v-radio>
            <v-radio label="Yes" value="Yes" color="success"></v-radio>
          </v-radio-group>
          <v-row v-if="dform.FBCResult == 'Yes'">
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.PCV"
                label="PCV(%):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.WBCTotal"
                type="number"
                :color="color"
              >
                <template v-slot:label>
                  WBC Total(x10<sup>9</sup>/L):
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.Neut"
                label="Neut(%):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.Lymph"
                label="Lymph(%):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.Baso"
                label="Baso(%):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.Eosin"
                label="Eosin(%):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.Platelete"
                label="Platelet(%):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.MCV"
                label="MCV(%):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.MCHC"
                label="MCHC(%):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.Investigation1"
                label="Investigation 1:"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.Result1"
                label="Result 1:"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.Investigation2"
                label="Investigation 2:"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.Result2"
                label="Result 2:"
                :color="color"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model.trim="dform.BloodCultureResult"
                label="Blood Culture Result:"
                :color="color"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="5">
              <v-radio-group v-model.trim="dform.BloodTransfusion" dense row>
                <span class="mr-3">Blood Transfusions: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col
              v-if="dform.BloodTransfusion == 'Yes'"
              cols="12"
              sm="5"
              md="3"
            >
              <v-text-field
                v-model.trim="dform.BloodTransfusionNumber"
                label="Number of Blood Transfusions:"
                :color="color"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="dform.BloodTransfusion == 'Yes'"
              cols="12"
              sm="7"
              md="4"
            >
              <v-textarea
                v-model.trim="dform.BloodTransfusionIndication"
                label="Indication for Blood Transfusion:"
                :color="color"
                auto-grow
                rows="1"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-radio-group v-model.trim="dform.Phototherapy" dense row>
                <span class="mr-3">Phototherapy during Admission: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6">
              <v-radio-group v-model.trim="dform.Surgery" dense row>
                <span class="mr-3">Surgery during Admission: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6">
              <v-radio-group v-model.trim="dform.IncubatorCare" dense row>
                <span class="mr-3">Incubator Care : </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <h5>
                Antibiotics durning admmission:
                <v-icon
                  :color="color"
                  @click="dform.antibioticNo++"
                  :disabled="dform.antibioticNo >= 5"
                  >mdi-plus</v-icon
                >
                <v-icon
                  :disabled="dform.antibioticNo <= ''"
                  @click="removeAntibiotic"
                  >mdi-minus</v-icon
                >
              </h5>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="n in dform.antibioticNo"
              :key="'antibiotic' + n"
            >
              <v-text-field
                v-model.number="dform['Antibiotic' + n]"
                label="Antibiotic"
                :color="color"
              ></v-text-field>
              <v-text-field
                v-model.trim="dform['AntibioticIndication' + n]"
                label="Indication"
                :color="color"
              ></v-text-field>
              <v-text-field
                v-model.trim="dform['AntibioticDuration' + n]"
                label="Duration (days)"
                :color="color"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
    <v-card-actions>
      <v-btn color="error" @click="$emit('prev', { step: 3 })" small>
        Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small color="green lighten-1" @click="saveChanges">
        Save and Continue
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model.trim="snackbar"
      :color="snackColor"
      bottom
      :timeout="5000"
    >
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin } from "@/mixins";
import { mapGetters } from "vuex";
export default {
  name: "InvestiGations",
  mixins: [snackMixin, editUserMixin],

  props: {
    formId: [String, Number]
  },
  data() {
    return {
      dform: this.initializeForm(),
      color: "#024202",
      cardColor: "#f0f7f0"
    };
  },
  computed: {
    ...mapGetters(["getCurUser"])
  },
  methods: {
    initializeForm() {
      return {
        PCVOnAdmission: "",
        RBSOnAdmission: "",
        FBCResult: "",
        PCV: "",
        WBCTotal: "",
        Neut: "",
        Lymph: "",
        Baso: "",
        Eosin: "",
        Platelete: "",
        MCV: "",
        MCHC: "",
        Investigation1: "",
        Result1: "",
        Investigation2: "",
        Result2: "",
        BloodCultureResult: "",
        BloodTransfusion: "",
        BloodTransfusionNumber: "",
        BloodTransfusionIndication: "",
        Phototherapy: "",
        Surgery: "",
        IncubatorCare: "",
        antibioticNo: 1,
        Antibiotic1: "",
        AntibioticIndication1: "",
        AntibioticDuration1: "",
        Antibiotic2: "",
        AntibioticIndication2: "",
        AntibioticDuration2: "",
        Antibiotic3: "",
        AntibioticIndication3: "",
        AntibioticDuration3: "",
        Antibiotic4: "",
        AntibioticIndication4: "",
        AntibioticDuration4: "",
        Antibiotic5: "",
        AntibioticIndication5: "",
        AntibioticDuration5: ""
      };
    },

    removeAntibiotic() {
      let n = this.dform.antibioticNo;
      this.dform["Antibiotic" + n] = "";
      this.dform["AntibioticIndication" + n] = "";
      this.dform["AntibioticDuration" + n] = "";
      this.dform.antibioticNo--;
    },

    saveChanges() {
      this.dform.id = this.formId;
      this.dform.type = "investigations";
      this.loading = true;
      this.dform.modified_by = this.getCurUser;
      apiClient
        .put("/index", this.dform)
        .then(res => {
          if (res.data.ok) {
            this.loading = false;
            this.displayMsg("Changes save Successfully");
            this.$emit("updated-four", { step: 5 });
          } else {
            this.displayMsg(res.data, "error");
            this.loading = false;
          }
        })
        .catch(err => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
