<template>
  <div>
    <v-form>
      <v-card class="mb-8" :color="cardColor">
        <v-container>
          <h3 class=" text-center mb-2">
            Baby Data
          </h3>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.BabyDob"
                type="date"
                label="Date of Birth:"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.DateOfAdmission"
                type="date"
                label="Date of Admission:"
                :color="color"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-radio-group v-model.trim="dform.Sex" dense name="gender" row>
                <span class="mr-3">Sex: </span>
                <v-radio label="Male" value="Male" color="success"></v-radio>
                <v-radio
                  label="Female"
                  value="Female"
                  color="success"
                ></v-radio>
                <v-radio label="DSD" value="DSD" color="success"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.GAInWeeks"
                label="Gestational Age (weeks):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="7">
              <v-text-field
                v-model.trim="dform.AdmissionAge"
                label="Age at Admission (hours/days if older than 72 hrs):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="5">
              <v-select
                v-model.trim="dform.AdmissionAgeUnit"
                :items="units"
                label="Unit:"
                :color="color"
                item-color="success"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="7">
              <v-text-field
                v-model.trim="dform.IllnessDuration"
                label="Time duration between onset of illness to presentation in facility:"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5">
              <v-select
                v-model.trim="dform.IllnessDurationUnit"
                :items="units"
                label="Unit:"
                :color="color"
                item-color="success"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.BirthWeight"
                label="Birth Weight(Kg):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.PresentationWeight"
                label="Weight at Presentation(Kg):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
       <v-card class="mt-8" :color="cardColor">
          <v-container>
            <h3 class="text-center">
              Morbidieties During Admission</h3>
              <v-row>
              <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Pda" dense row>
                <span class="mr-3">PDA: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Bpd" dense row>
                <span class="mr-3">BPD: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Nec" dense row>
                <span class="mr-3">NEC: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">

              <v-radio-group v-model.trim="dform.Lons" dense row>
                <span class="mr-3">LONS: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Rop" dense row>
                <span class="mr-3">ROP: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Ivh" dense row>
                <span class="mr-3">IVH: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Aop" dense row>
                <span class="mr-3">AOP: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            </v-row>
            </v-container>

            <v-container class="mt-8">
            <h3 class="text-center">
              Intervention During Admission</h3>
              <span>
              <b>Respiratory Support:</b></span>
              <v-row>
              <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Cpap" dense row>
                <span class="mr-3">CPAP: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Mv" dense row>
                <span class="mr-3">MV: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Others" dense row>
                <span class="mr-3">Others: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">

              <v-radio-group v-model.trim="dform.Surfactant" dense row>
                <span class="mr-3"><b>Surfactant:</b> </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Caffeine" dense row>
                <span class="mr-3"><b>Caffeine:</b> </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Mannitol" dense row>
                <span class="mr-3"><b>Mannitol:</b> </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            </v-row>
          </v-container>

          </v-card>


      <v-card class="mb-8" :color="cardColor">
        <v-container>
          <h3 class="text-center mb-2">Patients Examination</h3>
          <v-row>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model.trim="dform.PresentingComplaints"
                :items="complaints"
                label="Presenting Complaints (Type if not in options):"
                :color="color"
                item-color="success"
                multiple
              >
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <v-combobox
                v-model.trim="dform.GeneralExamination"
                :items="exams"
                label="General Examination (Type if not in options):"
                :color="color"
                item-color="success"
                multiple
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.SPO2OnAdmission"
                type="number"
                :color="color"
              >
                <template v-slot:label>
                  SPO<sub>2</sub> at Admission (%):
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.OFC"
                label="OFC(cm):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.OFCLength"
                label="Length(cm):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.HeadLag" dense row>
                <span class="mr-3">Head Lag: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Suck" dense row>
                <span class="mr-3">Suck: </span>
                <v-radio
                  label="Present"
                  value="Present"
                  color="success"
                ></v-radio>
                <v-radio
                  label="Absent"
                  value="Absent"
                  color="success"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.RespiratoryDistress" dense row>
                <span class="mr-3">Respiratory Distress: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col v-if="dform.RespiratoryDistress == 'Yes'" cols="12" sm="6">
              <v-combobox
                v-model.trim="dform.RespiratoryEvidence"
                :items="respitoryEvidence"
                label="Respitory Evidence (Type if not in options):"
                :color="color"
                item-color="success"
                multiple
              >
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.RespiratoryRate"
                label="Respiratory Rate:"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.HeartRate"
                label="Heart Rate:"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model.trim="dform.Murmur" dense row>
                <span class="mr-3">Murmur: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                v-model.trim="dform.Abdomen"
                :items="abdomen"
                label="Abdomen (Type if not in options):"
                :color="color"
                item-color="success"
                multiple
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.trim="dform.TempOnAdmission"
                type="number"
                :color="color"
              >
                <template v-slot:label>
                  Temperature at Admission(<sup>o</sup>C)
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-col cols="12">
            <h5>
              Initial Diagnosis at Admission:
              <v-icon
                :color="color"
                @click="dform.initialDiagnosisNo++"
                :disabled="dform.initialDiagnosisNo >= 5"
                >mdi-plus</v-icon
              >
              <v-icon
                :disabled="dform.initialDiagnosisNo <= ''"
                @click="removeInitialDiagnosis"
                >mdi-minus</v-icon
              >
            </h5>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-for="n in dform.initialDiagnosisNo"
            :key="'initialDiagnosis' + n"
          >
            <v-text-field
              v-model.number="dform['InitialDiagnosis' + n]"
              label="Initial Diagnosis"
              :color="color"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <h5>
              Other Morbidities Developed on Admission:
              <v-icon
                :color="color"
                @click="dform.morbiditiesNo++"
                :disabled="dform.morbiditiesNo >= 5"
                >mdi-plus</v-icon
              >
              <v-icon
                :disabled="dform.morbiditiesNo <= ''"
                @click="removeMorbidities"
                >mdi-minus</v-icon
              >
            </h5>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-for="m in dform.morbiditiesNo"
            :key="'morbidities' + m"
          >
            <v-text-field
              v-model.number="dform['MorbiditiesOnAdmission' + m]"
              label="Other Morbidities Developed on Admission:"
              :color="color"
            ></v-text-field>
          </v-col>
        
            

          </v-container>
      
      </v-card>
    </v-form>
    <v-card-actions>
      <v-btn color="error" @click="$emit('prev', { step: 2 })" small>
        Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small color="green lighten-1" @click="saveChanges">
        Save and Continue
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model.trim="snackbar"
      :color="snackColor"
      bottom
      :timeout="5000"
    >
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin } from "@/mixins";
import { mapGetters } from "vuex";
export default {
  name: "BabyData",

  mixins: [snackMixin, editUserMixin],

  props: {
    formId: [String, Number]
  },
  data() {
    return {
      dform: this.initializeForm(),
      color: "#024202",
      cardColor: "#f0f7f0",

      units: [
        { value: "", text: "Select...", disabled: true },
        "Mins",
        "Hours",
        "Days"
      ],
      complaints: [
        "Poor cry at birth",
        "Yellowness of the eyes",
        "Respiratory difficulty",
        "Fever",
        "Convulsion"
      ],
      exams: [
        "Pallor",
        "Jaundice",
        "Cyanosis",
        "Fever",
        "Oedema",
        "Hypothermia"
      ],
      respitoryEvidence: [
        "Tachypnoea",
        "Subcostal recession",
        "Intercostal recession",
        "Grunting",
        "Stridor"
      ],
      abdomen: [
        "Distended",
        "Hepatomegaly",
        "Splenomegaly",
        "Ballotable kidney",
        "Ascites",
        "Normal"
      ]
    };
  },
  computed: {
    ...mapGetters(["getCurUser"])
  },
  methods: {
    initializeForm() {
      return {
        BabyDob: new Date().toISOString().substr(0, 10),
        DateOfAdmission: new Date().toISOString().substr(0, 10),
        Sex: "",
        GAInWeeks: "",
        AdmissionAge: "",
        AdmissionAgeUnit: "",
        IllnessDuration: "",
        IllnessDurationUnit: "",
        BirthWeight: "",
        PresentationWeight: "",
        PresentingComplaints: [],
        GeneralExamination: [],
        SPO2OnAdmission: "",
        OFC: "",
        OFCLength: "",
        HeadLag: "",
        Suck: "",
        RespiratoryDistress: "",
        RespiratoryEvidence: [],
        RespiratoryRate: "",
        HeartRate: "",
        Murmur: "",
        Abdomen: [],
        TempOnAdmission: "",
        initialDiagnosisNo: 1,
        InitialDiagnosis1: "",
        InitialDiagnosis2: "",
        InitialDiagnosis3: "",
        InitialDiagnosis4: "",
        InitialDiagnosis5: "",
        morbiditiesNo: "1",
        MorbiditiesOnAdmission1: "",
        MorbiditiesOnAdmission2: "",
        MorbiditiesOnAdmission3: "",
        MorbiditiesOnAdmission4: "",
        MorbiditiesOnAdmission5: "",
        Pda: "",
        Bdp: "",
        Nec: "",
        Lons: "",
        Rop: "",
        Ivh: "",
        Aop: "",
        Cpap: "",
        Mv: "",
        Others: "",
        Surfactant: "",
        Caffeine: "",
        Mannitol: ""
      };
    },

    removeMorbidities() {
      let m = this.dform.morbiditiesNo;
      this.dform["InitialDiagnosis" + m] = "";
      this.dform.morbiditiesNo--;
    },
    removeInitialDiagnosis() {
      let n = this.dform.initialDiagnosisNo;
      this.dform["MorbiditiesOnAdmission" + n] = "";
      this.dform.initialDiagnosisNo--;
    },

    saveChanges() {
      this.dform.id = this.formId;
      this.dform.type = "babydata";
      this.loading = true;
      this.dform.modified_by = this.getCurUser;
      apiClient
        .put("/index", this.dform)
        .then(res => {
          if (res.data.ok) {
            this.displayMsg("Changes save Successfully");
            this.loading = false;
            this.$emit("updated-three", { step: 4 });
          } else {
            this.displayMsg(res.data, "error");
            this.loading = false;
          }
        })
        .catch(err => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    }
  }
};
</script>