<template>
  <div>
    <v-form ref="dform" lazy-validation @submit.prevent>
      <v-card class="mb-8" :color="cardColor">
        <v-container>
          <h3 class=" text-center mb-2">
            Maternal Demographics
          </h3>
          <v-row>
            <v-col cols="12" md="6">
              <v-textarea
                v-model.trim="dform.Address"
                :color="color"
                auto-grow
                rows="1"
                label="Address:"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="dform.PhoneNo"
                type="number"
                label="Contact Phone Number on Case Note:"
                :color="color"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.MatAge"
                label="Mother's Age (years):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.MatParity"
                label="Mother's Parity:"
                :color="color"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model.trim="dform.MatEdu"
                :items="education"
                label="Maternal Highest Level of Education:"
                :color="color"
                item-color="success"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.MatOccupation"
                label="Mother's Occupation:"
                :color="color"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mb-8" :color="cardColor">
        <v-container>
          <h3 class="text-center my-2">
            Maternal Obstetric History and Paternal History
          </h3>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model.trim="dform.MatPregCond"
                :color="color"
                auto-grow
                rows="1"
                label="Maternal Medical Conditions in Pregnancy:"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-radio-group v-model.trim="dform.MatPregLoss" dense row>
                <span class="mr-3">Previous Pregnancy Loss: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6">
              <v-radio-group
                v-model.trim="dform.MatPrevNeonatalDeath"
                dense
                row
              >
                <span class="mr-3">Previous Neonatal Death: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                v-model.trim="dform.MatIllnessOnAdmission"
                :color="color"
                auto-grow
                rows="1"
                label="Maternal Illness on Admission:"
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-radio-group dense v-model.trim="dform.Anc" row>
                <span class="mr-3">ANC: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col v-if="dform.Anc == 'Yes'" cols="12" sm="6">
              <v-radio-group dense row v-model.trim="dform.AncFourVisits">
                <span class="mr-3">ANC upto Four Visits: </span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col v-if="dform.Anc == 'Yes'" cols="12" sm="6">
              <v-select
                v-model.trim="dform.AncPlace"
                :items="healthcare1"
                label="Place of ANC"
                item-text="place"
                :color="color"
                item-color="success"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-combobox
                v-model.trim="dform.DeliveryPlace"
                :items="healthcare2"
                label="Place of Delivery (Type if not in options)"
                item-text="place"
                :color="color"
                item-color="success"
              >
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model.trim="dform.DeliveryMode"
                :items="deliveryMode"
                label="Mode of Delivery:"
                item-text="mode"
                :color="color"
                item-color="success"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" v-if="csDelivery">
              <v-textarea
                v-model.trim="dform.CSIndication"
                :color="color"
                auto-grow
                rows="1"
                label="C/S indication:"
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.PatAge"
                label="Father's Age(years):"
                type="number"
                :color="color"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model.trim="dform.PatEdu"
                :items="education"
                label="Father's Highest Level of Education:"
                :color="color"
                item-color="success"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.PatOccupation"
                label="Father's Occupation:"
                :color="color"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
    <v-card-actions>
      <v-btn color="error" @click="$emit('prev', { step: 1 })" small>
        Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small color="green lighten-1" @click="saveChanges">
        Save and Continue
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model.trim="snackbar"
      :color="snackColor"
      bottom
      :timeout="5000"
    >
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin } from "@/mixins";
import { mapGetters } from "vuex";
export default {
  name: "MatPatDemo",

  mixins: [snackMixin, editUserMixin],

  props: {
    formId: [String, Number]
  },
  data() {
    return {
      dform: this.initializeForm(),
      color: "#024202",
      cardColor: "#f0f7f0",

      education: [
        { value: "", text: "Select...", disabled: true },
        "Nil",
        "Primary",
        "Secondary",
        "Tertiary",
        "Postgraduate"
      ],

      healthcare1: [
        { value: "", place: "Select...", disabled: true },
        "Primary Health Care",
        "Community Health Care",
        "General Hospital",
        "Private Hospital",
        "Tertiary"
      ],
      healthcare2: [
        { value: "", place: "Select...", disabled: true },
        "Primary Health Care ",
        "Community Health Care",
        "General Hospital",
        "Private Hospital",
        "Mission House",
        "FETHI",
        "Tertiary",
        "Home",
        "Others"
      ],
      deliveryMode: [
        { value: "", mode: "Select...", disabled: true },
        "SVD",
        "Breech",
        "Instrumental",
        "ELCS",
        "EMCS"
      ]
    };
  },

  computed: {
    ...mapGetters(["getCurUser"]),
    csDelivery() {
      return (
        this.dform.DeliveryMode == "ELCS" ||
        this.dform.DeliveryMode == "EMCS"
      );
    }
  },

  methods: {
    initializeForm() {
      return {
        Address: "",
        PhoneNo: "",
        MatAge: "",
        MatParity: "",
        MatEdu: "",
        MatOccupation: "",
        MatPregCond: "",
        MatPregLoss: "",
        MatPrevNeonatalDeath: "",
        MatIllnessOnAdmission: "",
        Anc: "",
        AncFourVisits: "",
        AncPlace: "",
        DeliveryPlace: "",
        DeliveryMode: "",
        CSIndication: "",
        PatAge: "",
        PatEdu: "",
        PatOccupation: ""
      };
    },

    saveChanges() {
      this.dform.id = this.formId;
      this.dform.type = "matpatdemo";
      this.loading = true;
      this.dform.modified_by = this.getCurUser;
      apiClient
        .put("/index", this.dform)
        .then(res => {
          if (res.data.ok) {
            this.displayMsg("Changes save Successfully");
            this.loading = false;
            this.$emit("updated-two", { step: 3 });
          } else {
            this.displayMsg(res.data, "error");
            this.loading = false;
          }
        })
        .catch(err => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    }
  }
};
</script>
