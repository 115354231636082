<template>
  <div>
    <v-form>
      <v-card class="mb-8" :color="cardColor">
        <v-container>
          <h3>Preterm</h3>

          <v-col cols="12">
            <v-radio-group v-model.trim="dform.Preterm" dense row>
              <span class="mr-3">Preterm</span>
              <v-radio label="Yes" value="Yes" color="success"></v-radio>
              <v-radio label="No" value="No" color="success"></v-radio>
            </v-radio-group>
          </v-col>
          <v-row v-if="dform.Preterm == 'Yes'">
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="dform.PNABirthWeightRegain"
                label="At what postnatal age is birth weight regained (days)?"
                :color="color"
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="dform.PNAOralFeeds"
                label="At what postnatal age was oral feeds at 120mls/kg achieved(days)?"
                :color="color"
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-radio-group v-model.trim="dform.Discharge" dense row>
                <span class="mr-3">Discharge</span>
                <v-radio label="Yes" value="Yes" color="success"></v-radio>
                <v-radio label="No" value="No" color="success"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6" v-if="dform.Discharge == 'Yes'">
              <v-text-field
                v-model.trim="dform.WeightAtDischarge"
                type="number"
                label="Weight at discharge (grams):"
                :color="color"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="dform.Discharge == 'Yes'">
              <v-text-field
                v-model.trim="dform.PNAAtDischarge"
                label="Postnatal age at discharge (days):"
                :color="color"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="dform.Discharge == 'Yes'">
              <v-text-field
                v-model.trim="dform.PCAAtDischarge"
                label="PCA at discharge:"
                :color="color"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card :color="cardColor">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h5>
                Final Diagnosis:
                <v-icon
                  :color="color"
                  @click="dform.finalDiagnosisNo++"
                  :disabled="dform.finalDiagnosisNo >= 2"
                  >mdi-plus</v-icon
                >
                <v-icon
                  :disabled="dform.finalDiagnosisNo <= ''"
                  @click="removefinalDiagnosis"
                  >mdi-minus</v-icon
                >
              </h5>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              v-for="n in dform.finalDiagnosisNo"
              :key="'finalDiagnosis' + n"
            >
              <v-text-field
                v-model.trim="dform['FinalDiagnosis' + n]"
                label="Final Diagnosis"
                :color="color"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="dform.AdmissionDuration"
                type="number"
                label="Duration of Admission till discharge/death (days):"
                :color="color"
                class="text-wrap"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                :items="units"
                label="Unit:"
                v-model.trim="dform.AdmissionDurationUnit"
                :color="color"
                item-color="success"
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model.trim="dform.IllnessOutcome"
                :items="illnessOutcome"
                label="Outcome of Illness"
                :color="color"
                item-color="success"
              >
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model.trim="dform.OutcomeReason"
                :color="color"
                auto-grow
                rows="1"
              >
                <template v-slot:label>
                  {{
                    dform.IllnessOutcome == "Discharged"
                      ? "State Morbidities at Discharge:"
                      : dform.IllnessOutcome == "Death"
                      ? "Possible Causes of Death:"
                      : `State Reason(s) for ${dform.IllnessOutcome}:`
                  }}
                </template>
              </v-textarea>
            </v-col>
          </v-row>

          <v-switch
            v-model.trim="dform.Submit"
            label="Close Case"
            color="success"
            inset
            :false-value="0"
            :true-value="1"
          ></v-switch>
        </v-container>
      </v-card>
    </v-form>
    <v-card-actions>
      <v-btn color="error" @click="$emit('prev', { step: 4 })" small>
        Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small color="green lighten-1" @click="saveChanges">
        Save Changes
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model.trim="snackbar"
      :color="snackColor"
      bottom
      :timeout="5000"
    >
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin } from "@/mixins";
import { mapGetters } from "vuex";
export default {
  name: "ResultOutcomes",
  mixins: [snackMixin, editUserMixin],

  props: {
    formId: [String, Number]
  },
  data() {
    return {
      dform: this.initializeForm(),
      color: "#024202",
      cardColor: "#f0f7f0",

      illnessOutcome: ["Discharged", "DAMA", "Referred", "Death"],
      units: [
        { value: "", text: "Select...", disabled: true },
        "Mins",
        "Hours",
        "Days"
      ]
    };
  },
  computed: {
    ...mapGetters(["getCurUser"])
  },
  methods: {
    initializeForm() {
      return {
        Preterm: "",
        PNABirthWeightRegain: "",
        PNAOralFeeds: "",
        Discharge: "",
        PNAAtDischarge: "",
        WeightAtDischarge: "",
        PCAAtDischarge: "",
        finalDiagnosisNo: 1,
        FinalDiagnosis1: "",
        FinalDiagnosis2: "",
        AdmissionDuration: "",
        AdmissionDurationUnit: "",
        IllnessOutcome: "",
        OutcomeReason: "",
        Submit: 0
      };
    },

    removefinalDiagnosis() {
      let n = this.dform.finalDiagnosisNo;
      this.dform["FinalDiagnosis" + n] = "";
      this.dform.finalDiagnosisNo--;
    },

    saveChanges() {
      this.dform.id = this.formId;
      this.dform.type = "resultoutcomes";
      this.loading = true;
      this.dform.modified_by = this.getCurUser;
      // Do Validation here
      apiClient
        .put("/index", this.dform)
        .then(res => {
          if (res.data.ok) {
            this.displayMsg(
              "Changes save Successfully, You will be redirected..."
            );
            this.loading = false;
            this.$emit("updated-four", { step: 5 });
            setTimeout(() => this.$router.push({ name: "Dashboard" }), 5000);
          } else {
            this.displayMsg(res.data, "error");
            this.loading = false;
          }
        })
        .catch(err => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
