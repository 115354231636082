<template>
  <div>
    <h3 class=" text-center mb-2">Account Opening</h3>
    <v-card class="mb-7" :color="cardColor">
      <v-container>
        <v-form ref="dform" lazy-validation @submit.prevent>
          <v-text-field
            v-model.trim="dform.Name"
            label="Name:"
            :color="color"
            :rules="[rules.required]"
            auto-grow
            rows="1"
          >
            <template v-if="dform.id" v-slot:append>
              <v-btn icon @click="edit.Name = !edit.Name">
                <v-icon v-if="edit.Name">mdi-close</v-icon>
                <v-icon v-else>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <v-text-field
            v-model.trim="dform.HospNo"
            label="Hospital Number:"
            :color="color"
            :rules="[rules.required]"
          >
            <template v-if="dform.id" v-slot:append>
              <v-btn icon @click="edit.HospNo = !edit.HospNo">
                <v-icon v-if="edit.HospNo">mdi-close</v-icon>
                <v-icon v-else>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-form>
      </v-container>
    </v-card>
    <v-card-actions>
      <v-btn small color="error" @click="reset">
        Cancel
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        small
        v-if="dform.id"
        color="green lighten-1"
        :loading="loading"
        @click="saveChanges(edit.Name ? 'Name' : edit.HospNo ? 'HospNo' : '')"
      >
        Save Changes
      </v-btn>

      <v-btn
        v-else
        small
        :loading="loading"
        color="green lighten-1"
        @click="createAccount"
        :disabled="!!dform.id"
      >
        Create Account
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model.trim="snackbar"
      :color="snackColor"
      bottom
      :timeout="5000"
    >
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin } from "@/mixins";
import { mapGetters } from "vuex";

export default {
  name: "OpenAccount",
  mixins: [snackMixin, editUserMixin],

  data() {
    return {
      dform: this.initializeForm(),
      color: "#024202",
      cardColor: "#f0f7f0",
      rules: { required: value => !!value || "Please fill this field." },
      edit: { Name: false, HospNo: false }
    };
  },

  computed: {
    ...mapGetters(["getCurUser"])
  },
  methods: {
    initializeForm() {
      return {
        id: "",
        Name: "",
        HospNo: "",
        created_by: "",
        modified_by: ""
      };
    },
    createAccount() {
      if (!this.$refs.dform.validate()) {
        return;
      }
      this.loading = true;
      this.dform.created_by = this.dform.modified_by = this.getCurUser;
      apiClient
        .post("/", this.dform)
        .then(res => {
          if (res.data.id) {
            this.dform = { ...res.data };
            this.loading = false;
            this.$emit("create-account", { user: this.dform });
            this.$emit("updated-one", { step: 2 });
            this.displayMsg("New account created successfully");
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch(err => {
          this.displayMsg(err.message, "error");
        });
    },

    saveChanges() {
      this.loading = true;
      this.dform.type = "openaccount";
      this.dform.modified_by = this.getCurUser;
      apiClient
        .put("/index", this.dform)
        .then(res => {
          if (res.data.ok) {
            this.loading = false;
            this.$emit("updated-one", { step: 2 });
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch(err => this.displayMsg(err.message, "error"));
    },
    reset() {
      this.$refs.dform.reset();
    }
  }
};
</script>
