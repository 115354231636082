<template>
  <div>
    <v-stepper v-model="e1" class="mt-6">
      <v-stepper-header>
        <v-stepper-step editable :complete="e1 > 1" step="1" :color="color">
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :editable="editSteppers"
          :complete="e1 > 2"
          step="2"
          :color="color"
        >
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :editable="editSteppers"
          :complete="e1 > 3"
          step="3"
          :color="color"
        >
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :editable="editSteppers"
          :complete="e1 > 4"
          step="4"
          :color="color"
        >
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :editable="editSteppers" step="5" :color="color">
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <OpenAccount
            @create-account="accoutCreated"
            @updated-one="moveStepper"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <MatPatDemo
            @updated-two="moveStepper"
            :form-id="formId"
            @prev="moveStepper"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <BabyData
            @updated-three="moveStepper"
            :form-id="formId"
            @prev="moveStepper"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <InvestiGations
            @updated-four="moveStepper"
            :form-id="formId"
            @prev="moveStepper"
          />
        </v-stepper-content>
        <v-stepper-content step="5">
          <ResultOutcomes @prev="moveStepper" :form-id="formId" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from "@/mixins";
import OpenAccount from "@/components/OpenAccount.vue";
import MatPatDemo from "@/components/MatPatDemo.vue";
import BabyData from "@/components/BabyData.vue";
import InvestiGations from "@/components/InvestiGations.vue";
import ResultOutcomes from "@/components/ResultOutcomes.vue";
export default {
  name: "PatientsApplication",
  mixins: [snackMixin],

  props: {
    editUser: {
      type: Object,
      default: null
    }
  },
  components: {
    OpenAccount,
    MatPatDemo,
    BabyData,
    InvestiGations,
    ResultOutcomes
  },

  data() {
    return {
      user: {},
      e1: 1,
      color: "#024202"
    };
  },

  computed: {
    formId() {
      let uid = this.user.id && this.user.id;
      let eid = this.editUser && this.editUser.id;
      return uid || eid;
    },
    editSteppers() {
      return !!this.formId;
      // return true
    }
  },

  methods: {
    moveStepper({ user, step }) {
      user && user.id && (this.user = user);
      this.displayMsg("Changes save Successfully");
      step && (this.e1 = step);
    },

    accoutCreated({ user }) {
      user.id && (this.user = user);
    }
  }
};
</script>
